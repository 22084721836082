
import { memo } from "react";
import BotonCard from "@/app/components/Botones/BotonCard/BotonCard";
import SistemaOnline from ".././../../../../../JSON/sistemaOnline.json";
import { PublicacionesAgrupadasType } from "@/app/interfaces/interfaces";

interface CardGridProps {
  publicacionesAgrupadas: PublicacionesAgrupadasType;
  servicioSeleccionado: string;
  onBotonCardClick: (subcategoria: string, value: string) => void;
  onRedireccionClick: (url?: string) => void;
}

const CardGrid = memo(({
  publicacionesAgrupadas,
  servicioSeleccionado,
  onBotonCardClick,
  onRedireccionClick
}: CardGridProps) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-[26px] gap-x-[66px] md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {Object.keys(publicacionesAgrupadas).map((subcategoria: string, index) => (
        <BotonCard
          key={subcategoria}
          mostrarIcono
          fondo="/fondo2.svg"
          botonLabel={subcategoria}
          value={subcategoria}
          onClick={() => onBotonCardClick(subcategoria, subcategoria)}
          index={index}
        />
      ))}
      
      {servicioSeleccionado.toLowerCase() === "servicios" && (
        <BotonCard
          mostrarIcono
          fondo="/fondo2.svg"
          botonLabel="Delegación Departamental"
          value="Delegación"
          onClick={() => onBotonCardClick("Delegación de Partamental", "Delegación")}
          index={1}
        />
      )}
      
      {servicioSeleccionado === "Sistema Online" &&
        SistemaOnline.map((link, index) => (
          <div key={link.id}>
            <BotonCard
              mostrarIcono
              fondo="/fondo2.svg"
              botonLabel={link.nombre}
              value={link.nombre}
              onClick={() => onRedireccionClick(link.url)}
              index={index}
            />
          </div>
        ))}
    </div>
  );
});

CardGrid.displayName = "CardGrid";
export default CardGrid;